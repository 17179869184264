jQuery(document).ready(function ($) {
  //Fix for google recaptcha errors when duplicate forms are present
  $(".ginput_recaptcha").each(function (i) {
    var thisID = $(this).attr("id");
    $(this).attr("id", thisID + "_" + i);
  });
});

/**
 * Set iframe height to document scroll height
 *
 */
function resize_IFrames_by_message(event) {
  if (event.origin !== "https://alitisinvestmentcounsel.com") {
    // console.log('message origin bad');
    return;
  }

  let my_scroll_ht = event.data.toString().concat("px");

  const iframes = document.querySelectorAll(
    'iframe[src^="https://alitisinvestmentcounsel.com"]'
  );
  for (let i = iframes.length - 1; 0 <= i; --i) {
    iframes[i].style.height = my_scroll_ht;
  }
}

window.addEventListener("message", resize_IFrames_by_message);

jQuery(document).ready(function ($) {
  // Function to scroll to the target div with an offset
  function scrollToTarget() {
    const targetDiv = $("#job-application");

    if (targetDiv.length > 0) {
      // Offset position to scroll x number pixels above the target div
      const offsetPosition = targetDiv.offset().top - 200;

      // Scroll to the offset position
      $("html, body").animate({
        scrollTop: offsetPosition
      }, 800);
    }
  }

  $("#scroll-button").on("click", scrollToTarget); 
});

